/* AboutArambh.css */
.hero-section2 {
    position: relative;
    height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
  }
  
  .content {
    z-index: 2;
    padding-top: 100px;
  }
  
  .subtitle {
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .title {
    color: #fff;
    font-size: 5rem;
    font-weight: 700;
  }
  
  .title span {
    color: #ff9800;
  }
  
  .details {
    margin-bottom: 20px;
  }
  
  .register-btn {
    font-family: 'Roboto', sans-serif;
    background-color: #ff9800;
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .register-btn:hover {
    background-color: #e55310;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transform: translateY(-3px);
  }
  
  .countdown {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .time-box {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }
  
  .time-box span {
    display: block;
    font-size: 2rem;
    font-weight: bold;
  }
  
  .time-box p {
    margin-top: 5px;
    font-size: 1rem;
    color: lightgray;
  }
  
  /* Add responsive styles similar to AboutFilmscreening */
  @media (max-width: 768px) {
    .title {
      font-size: 3rem;
    }
    
    .subtitle {
      font-size: 1.2rem;
    }
  
    .register-btn {
      font-size: 1rem;
      padding: 8px 16px;
    }
  
    .countdown {
      gap: 10px;
    }
  
    .time-box {
      padding: 15px;
    }
  
    .time-box span {
      font-size: 1.5rem;
    }
  
    .time-box p {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .title {
      font-size: 2.5rem;
    }
  
    .subtitle {
      font-size: 1rem;
    }
  
    .register-btn {
      font-size: 0.9rem;
      padding: 6px 12px;
    }
  
    .countdown {
      gap: 10px;
      padding: 20px;
    }
  
    .time-box {
      padding: 10px;
    }
  
    .time-box span {
      font-size: 1.2rem;
    }
  
    .time-box p {
      font-size: 0.8rem;
    }
  }
  
  /* Extra small screens */
  @media (max-width: 320px) {
    .title {
      font-size: 2rem;
    }
  
    .subtitle {
      font-size: 0.9rem;
    }
  
    .register-btn {
      font-size: 0.8rem;
      padding: 5px 10px;
    }
  
    .countdown {
      padding: 10px;
    }
  
    .time-box {
      padding: 8px;
    }
  
    .time-box span {
      font-size: 1rem;
    }
  
    .time-box p {
      font-size: 0.7rem;
    }
  }
  
  
  