body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.social-media-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 40vh;
  background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
  padding: 80px 20px;
  position: relative;
  overflow: hidden;
}

.social-media-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at 50% 50%, rgba(0,0,0,0.02) 0%, transparent 70%);
  pointer-events: none;
}

.title2 {
  font-family: 'august', sans-serif;
  font-size: 3.5rem;
  margin-bottom: 25px;
  color: #1a1a1a;
  text-align: center;
  position: relative;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s ease forwards;
}

.title2::after {
  content: '';
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  height: 3px;
  background: linear-gradient(90deg, transparent, #1a1a1a, transparent);
  opacity: 0;
  animation: expandWidth 0.8s ease forwards 0.4s;
}

.social-description {
  font-family: 'Bricolage', sans-serif;
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 50px;
  text-align: center;
  max-width: 700px;
  line-height: 1.6;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s ease forwards 0.2s;
}

.social-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  padding: 20px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s ease forwards 0.4s;
}

.social-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  position: relative;
  transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.icon {
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 18px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  position: relative;
  overflow: hidden;
}

.icon::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(255,255,255,0.2) 0%, transparent 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.icon img {
  width: 60%;
  height: auto;
  object-fit: contain;
  transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  filter: grayscale(20%);
}

.icon-name {
  margin-top: 12px;
  font-family: 'Bricolage', sans-serif;
  font-weight: 500;
  font-size: 0.95rem;
  color: #444;
  transition: all 0.3s ease;
  opacity: 0.8;
}

.social-icon:hover {
  transform: translateY(-8px);
}

.social-icon:hover .icon {
  transform: scale(1.1);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
}

.social-icon:hover .icon::before {
  opacity: 1;
}

.social-icon:hover .icon img {
  transform: scale(1.15);
  filter: grayscale(0%);
}

.social-icon:hover .icon-name {
  color: #000;
  opacity: 1;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes expandWidth {
  from {
    width: 0;
    opacity: 0;
  }
  to {
    width: 120px;
    opacity: 1;
  }
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .social-media-container {
    padding: 60px 15px;
  }

  .title2 {
    font-size: 2.8rem;
  }

  .social-description {
    font-size: 1.1rem;
    padding: 0 20px;
    margin-bottom: 40px;
  }

  .social-icons {
    gap: 30px;
  }

  .icon {
    width: 55px;
    height: 55px;
  }
}

@media (max-width: 480px) {
  .title2 {
    font-size: 2.4rem;
  }

  .social-description {
    font-size: 1rem;
  }

  .social-icons {
    gap: 25px;
  }

  .icon {
    width: 50px;
    height: 50px;
    border-radius: 15px;
  }

  .icon-name {
    font-size: 0.85rem;
  }
}
