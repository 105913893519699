.admin-dashboard {
  min-height: 100vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  padding: 20px;
  color: #2d3748;
  font-family: 'Inter', sans-serif;
}

.dashboard-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.dashboard-header {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.header-left {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.header-title {
  font-size: 24px;
  font-weight: 700;
  color: #2d3748;
  margin: 0;
}

.welcome-message {
  font-size: 14px;
  color: #4a5568;
}

.header-actions {
  display: flex;
  gap: 12px;
  align-items: center;
}

.super-admin-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: linear-gradient(135deg, #4a90e2, #357abd);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 10;
}

.super-admin-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(74, 144, 226, 0.2);
}

.super-admin-button svg {
  width: 20px;
  height: 20px;
}

.logout-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #e53e3e;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 10;
}

.logout-button:hover {
  background: #c53030;
  transform: translateY(-2px);
}

.error-message {
  background: #fff5f5;
  border: 1px solid #feb2b2;
  color: #c53030;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 20px;
  font-size: 14px;
}

.section-title {
  font-size: 20px;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 20px;
}

.stats-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.stat-card {
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  gap: 16px;
  transition: transform 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-4px);
}

.stat-icon {
  width: 48px;
  height: 48px;
  background: #fdf2f8;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ec4899;
}

.stat-info {
  display: flex;
  flex-direction: column;
}

.stat-value {
  font-size: 24px;
  font-weight: 700;
  color: #2d3748;
}

.stat-label {
  font-size: 14px;
  color: #4a5568;
}

.join-requests-section {
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}

.no-requests-message {
  text-align: center;
  padding: 40px;
  color: #4a5568;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.requests-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.request-card {
  background: #f7fafc;
  padding: 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: transform 0.3s ease;
}

.request-card:hover {
  transform: translateY(-2px);
}

.request-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.request-name {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #2d3748;
}

.request-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.request-details p {
  margin: 0;
  font-size: 14px;
  color: #4a5568;
}

.request-details strong {
  color: #2d3748;
}

.request-actions {
  display: flex;
  gap: 12px;
  margin-top: auto;
}

.approve-button,
.reject-button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.approve-button {
  background: #ec4899;
  color: white;
}

.approve-button:hover {
  background: #db2777;
  transform: translateY(-2px);
}

.reject-button {
  background: #f56565;
  color: white;
}

.reject-button:hover {
  background: #e53e3e;
  transform: translateY(-2px);
}

.quick-actions {
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.actions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  background: #f7fafc;
  border: none;
  border-radius: 12px;
  color: #4a5568;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.action-button:hover {
  background: #edf2f7;
  transform: translateY(-2px);
}

.action-button svg {
  width: 24px;
  height: 24px;
  color: #4299e1;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .admin-dashboard {
    padding: 10px;
  }

  .dashboard-container {
    padding: 10px;
  }

  .dashboard-header {
    flex-direction: column;
    gap: 16px;
    text-align: center;
    padding: 16px;
  }

  .header-actions {
    width: 100%;
    justify-content: center;
  }

  .stats-container {
    grid-template-columns: 1fr;
  }

  .requests-grid {
    grid-template-columns: 1fr;
  }

  .request-card {
    padding: 16px;
  }

  .request-actions {
    flex-direction: column;
  }

  .actions-grid {
    grid-template-columns: 1fr;
  }

  .action-button {
    justify-content: center;
  }
}

/* Small Mobile Devices */
@media (max-width: 480px) {
  .header-title {
    font-size: 20px;
  }

  .welcome-message {
    font-size: 12px;
  }

  .super-admin-button,
  .logout-button {
    padding: 6px 12px;
    font-size: 12px;
  }

  .section-title {
    font-size: 18px;
    margin-bottom: 16px;
  }

  .stat-card {
    padding: 16px;
  }

  .stat-icon {
    width: 40px;
    height: 40px;
  }

  .stat-value {
    font-size: 20px;
  }

  .stat-label {
    font-size: 12px;
  }

  .request-name {
    font-size: 16px;
  }

  .request-details p {
    font-size: 12px;
  }

  .approve-button,
  .reject-button {
    padding: 6px 12px;
    font-size: 12px;
  }

  .action-button {
    padding: 12px;
    font-size: 14px;
  }
}

.stat-card.clickable {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.stat-card.clickable:hover {
  transform: translateY(-2px);
}

/* Modern Event Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 24px;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.modal-header h2 {
  margin: 0;
  color: #2c3e50;
  font-size: 1.5rem;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  color: #666;
  cursor: pointer;
  padding: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.close-button:hover {
  background-color: #f5f5f5;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #2c3e50;
  font-weight: 500;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.form-group input:focus {
  border-color: #4a90e2;
  outline: none;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.1);
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.btn-secondary {
  padding: 10px 20px;
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.btn-secondary:hover {
  background-color: #e5e5e5;
}

.btn-primary {
  padding: 10px 20px;
  background: linear-gradient(135deg, #4a90e2, #357abd);
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(74, 144, 226, 0.2);
}

.btn-primary:disabled {
  background: #ccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.error-message {
  background-color: #fee2e2;
  color: #dc2626;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 20px;
  font-size: 14px;
}

/* Events Management Section */
.events-management-section {
  margin-top: 2rem;
  padding: 2rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.section-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
}

.add-event-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #4169FF;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.add-event-button:hover {
  background-color: #3151c9;
  transform: translateY(-2px);
}

.add-event-button svg {
  width: 20px;
  height: 20px;
}

.events-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.no-events-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px;
  text-align: center;
  background: #f8fafc;
  border-radius: 16px;
  color: #64748b;
  grid-column: 1 / -1;
  gap: 16px;
}

.no-events-message svg {
  color: #94a3b8;
}

.no-events-message p {
  font-size: 1.1rem;
  margin: 0;
}

.event-card {
  background: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
}

.event-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.event-image {
  position: relative;
  height: 200px;
  background: #f1f5f9;
}

.event-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.event-card:hover .event-image img {
  transform: scale(1.05);
}

.event-category {
  position: absolute;
  top: 12px;
  right: 12px;
  background: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: 500;
  backdrop-filter: blur(4px);
}

.event-info {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.event-info h3 {
  margin: 0 0 12px;
  font-size: 1.25rem;
  color: #1e293b;
  font-weight: 600;
}

.event-description {
  color: #64748b;
  font-size: 0.95rem;
  line-height: 1.5;
  margin-bottom: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.event-details {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;
  font-size: 0.9rem;
  color: #64748b;
}

.event-details span {
  display: flex;
  align-items: center;
  gap: 8px;
}

.event-details svg {
  color: #6366f1;
}

.event-stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  padding-top: 16px;
  border-top: 1px solid #e2e8f0;
}

.event-stats span {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #64748b;
  font-size: 0.95rem;
  font-weight: 500;
}

.event-stats svg {
  color: #6366f1;
}

.event-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: auto;
}

.delete-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #fee2e2;
  color: #ef4444;
  border: none;
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.delete-button:hover {
  background: #fecaca;
  transform: translateY(-1px);
}

.delete-button svg {
  width: 16px;
  height: 16px;
}

@media (max-width: 640px) {
  .events-grid {
    grid-template-columns: 1fr;
  }

  .event-card {
    margin: 0;
  }

  .event-details {
    flex-direction: column;
    gap: 8px;
  }

  .event-stats {
    flex-direction: column;
    gap: 8px;
  }
}

.about-event-editor {
  position: relative;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  transition: all 0.3s ease;
}

.about-event-editor:focus-within {
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.1);
}

.about-event-editor textarea {
  width: 100%;
  padding: 16px;
  border: none;
  resize: vertical;
  min-height: 150px;
  font-family: inherit;
  line-height: 1.6;
  color: #333;
  background: transparent;
}

.about-event-editor textarea:focus {
  outline: none;
}

.editor-toolbar {
  display: flex;
  gap: 8px;
  padding: 8px 16px;
  background: #f8f9fa;
  border-top: 1px solid #e0e0e0;
}

.toolbar-button {
  padding: 6px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background: #fff;
  color: #666;
  cursor: pointer;
  transition: all 0.2s ease;
}

.toolbar-button:hover {
  background: #f0f0f0;
  color: #333;
}

.toolbar-button.active {
  background: #e3f2fd;
  color: #1976d2;
  border-color: #1976d2;
}

.google-form-input {
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
}

.google-form-input input {
  flex: 1;
  padding: 12px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.google-form-input input:focus {
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.1);
}

.form-link-preview {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #f8f9fa;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  color: #666;
  font-size: 14px;
}

.form-link-preview svg {
  color: #4a90e2;
}

.helper-text {
  display: block;
  margin-top: 8px;
  font-size: 12px;
  color: #666;
}

/* Error states */
.about-event-editor.error,
.google-form-input input.error {
  border-color: #dc3545;
}

.error-message {
  color: #dc3545;
  font-size: 12px;
  margin-top: 4px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .google-form-input {
    flex-direction: column;
    align-items: stretch;
  }

  .form-link-preview {
    justify-content: center;
  }

  .editor-toolbar {
    flex-wrap: wrap;
  }
}

/* Animation for tab transitions */
.form-section {
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Dashboard Tabs */
.dashboard-tabs {
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
  background: white;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.tab-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 20px;
  border: none;
  background: transparent;
  color: #6b7280;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.tab-button:hover {
  background: #f3f4f6;
  color: #4b5563;
}

.tab-button.active {
  background: #2563eb;
  color: white;
}

.tab-button svg {
  width: 20px;
  height: 20px;
}

/* Events Controls */
.events-controls {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 20px;
}

.search-box {
  position: relative;
  flex: 1;
  max-width: 300px;
}

.search-box input {
  width: 100%;
  padding: 10px 16px 10px 40px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 14px;
  transition: all 0.2s ease;
}

.search-box input:focus {
  border-color: #2563eb;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
  outline: none;
}

.search-box svg {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #9ca3af;
}

.sort-select {
  padding: 10px 16px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 14px;
  color: #4b5563;
  background-color: white;
  cursor: pointer;
  transition: all 0.2s ease;
}

.sort-select:focus {
  border-color: #2563eb;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
  outline: none;
}

/* Users Management Section */
.users-management-section {
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.users-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.user-card {
  background: #f9fafb;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  transition: all 0.2s ease;
}

.user-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.user-info h3 {
  margin: 0 0 16px;
  font-size: 18px;
  color: #1f2937;
}

.user-info p {
  margin: 8px 0;
  font-size: 14px;
  color: #4b5563;
}

.user-info strong {
  color: #374151;
  font-weight: 600;
}

/* Status and Role Badges */
.status-badge, .role-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.status-badge.approved {
  background-color: #dcfce7;
  color: #166534;
}

.status-badge.pending {
  background-color: #fef3c7;
  color: #92400e;
}

.role-badge.super-admin {
  background-color: #dbeafe;
  color: #1e40af;
}

.role-badge.admin {
  background-color: #e0e7ff;
  color: #3730a3;
}

.role-badge.user {
  background-color: #f3f4f6;
  color: #374151;
}

/* Responsive Design */
@media (max-width: 768px) {
  .dashboard-tabs {
    flex-direction: column;
    padding: 12px;
  }

  .tab-button {
    width: 100%;
    justify-content: center;
  }

  .events-controls {
    flex-direction: column;
    align-items: stretch;
  }

  .search-box {
    max-width: none;
  }

  .users-grid {
    grid-template-columns: 1fr;
  }
} 