.hero-section {
    position: relative;
    height: auto;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 20px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}

.content {
    position: relative;
    z-index: 1;
    text-align: center;
}

.event-header {
    margin-bottom: 30px;
    animation: fadeIn 1s ease-in;
}

.title {
    font-size: 3.5rem;
    font-weight: bold;
    margin-bottom: 15px;
}

.event-poster {
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.event-info-section {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 30px;
    border-radius: 15px;
    margin-bottom: 30px;
    animation: fadeIn 1s ease-in;
}

.info-title {
    font-family: bricolage;
    font-size: 2.5rem;
    margin-bottom: 15px;
}

.details {
    font-family: bricolage;
    font-size: 1.3rem;
    line-height: 1.6;
}

.video-section {
    margin: 40px 0; /* Add top and bottom margin for spacing */
    animation: fadeIn 1s ease-in;
}

.video-container {
    display: flex;
    justify-content: center; /* Center align the video */
    align-items: center;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.1); /* Add a subtle background */
    border-radius: 15px; /* Rounded corners */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); /* Box shadow for depth */
}

.video-iframe {
    width: 100%;
    max-width: 800px;
    height: 450px;
    border: none;
    border-radius: 15px;
    margin: auto; /* Ensure margin auto for better centering */
}



/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Responsive Styles */
@media (max-width: 768px) {
    .title {
        font-size: 2.5rem; /* Smaller title on mobile */
    }

    .event-info-section {
        padding: 20px; /* Less padding on smaller screens */
    }

    .details {
        font-size: 1.1rem; /* Smaller text on mobile */
    }

    .video-iframe {
        height: 300px; /* Adjust video height on mobile */
    }

    .video-container {
        padding: 10px; /* Less padding on smaller screens */
    }
}






/* Center the 'Coming Soon' text in the middle of the screen */
.lcdiwali-coming-soon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    background-color: #f7f7f7; /* Light background for simplicity */
    position: relative;
    text-align: center;
}

/* Styling for the 'Coming Soon' text */
.lcdiwali-coming-soon-text {
    font-family: august;
    font-size: 10rem;
    font-weight: bold;
    color: #333;
}

/* Back Button in the top left corner */
.lcdiwali-back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: #d22c2c;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.lcdiwali-back-button:hover {
    background-color: #7a0d0d;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .lcdiwali-coming-soon-text {
        font-size: 5rem; /* Smaller font size for mobile screens */
    }

    .lcdiwali-back-button {
        padding: 8px 15px; /* Adjust button padding for mobile */
        font-size: 0.9rem;
    }
}
